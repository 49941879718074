<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                :type="passwordFieldType"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required|integer"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="phone"
                v-model="userData.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="phone number"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="Address"
            rules=""
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <b-form-textarea
                id="address"
                v-model="userData.address"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Current address"
                rows="2"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company users -->
          <validation-provider
            v-if="userData.role_id === roleOptions.find(o => o.code === 'su').value"
            #default="validationContext"
            name="Company users"
            :rules="userData.role_id === roleOptions.find(o => o.code === 'su').value ? 'required' : ''"
          >
            <b-form-group
              label="Company"
              label-for="company_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.company_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyUsers"
                :reduce="val => val.value"
                :clearable="false"
                input-id="company_id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            v-if="userData.role_id === roleOptions.find(o => o.code === 'su').value && userData.company_id === 0"
            #default="validationContext"
            name="Company Name"
            :rules="userData.role_id === roleOptions.find(o => o.code === 'su').value ? 'required' : ''"
          >
            <b-form-group
              label="Company Name"
              label-for="company_name"
            >
              <b-form-input
                id="company_name"
                v-model="userData.company_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Industry name -->
          <validation-provider
            v-if="userData.role_id === roleOptions.find(o => o.code === 'su').value"
            #default="validationContext"
            name="Industry Name"
            :rules="userData.role_id === roleOptions.find(o => o.code === 'su').value ? 'required' : ''"
          >
            <b-form-group
              label="Industry name"
              label-for="industry_name"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.industry_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="industryNames"
                :reduce="val => val.value"
                :clearable="false"
                input-id="industry_name"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Gender -->
          <validation-provider
            #default="validationContext"
            name="Gender"
            rules="required"
          >
            <b-form-group
              label="Gender"
              label-for="gender"
              :state="getValidationState(validationContext)"
            >
              <b-form-radio-group
                id="gender"
                v-model="userData.gender"
                :options="genders"
              />
              <!--              <v-select-->
              <!--                v-model="userData.gender"-->
              <!--                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
              <!--                :options="genders"-->
              <!--                :clearable="false"-->
              <!--                input-id="gender"-->
              <!--              />-->
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Join Date -->
          <validation-provider
            #default="validationContext"
            name="User Join Date"
          >
            <b-form-group
              label="Join Date"
              label-for="joindate"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="userData.birthdate"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y'}"
              />
              <!--              <b-form-datepicker-->
              <!--                id="birthdate"-->
              <!--                v-model="userData.birthdate"-->
              <!--                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"-->
              <!--                class="mb-1"-->
              <!--              />-->
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="role_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$store.state.currentUser.role.code === 'su' ? roleOptions.filter(el => {
                  return el.code === 'sp'
                }) : roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="role_id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <b-col
            md="8"
            offset-md="4"
          >
            <b-form-group>
              <b-form-checkbox
                id="status"
                v-model="userData.status"
                name="status"
                :value="true"
              >
                Active status
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="disableBtn"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BCol, BInputGroupAppend,
  BButton, BFormTextarea, BFormCheckbox, BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  required, alphaNum, integer, email,
} from '@validations'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import genders from '@/@fake-db/data/other/genders'
import industryNames from '@/@fake-db/data/other/industry_name_options'

export default {
  components: {
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BFormRadioGroup,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      email,
      genders,
      industryNames,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      email: '',
      address: '',
      phone: null,
      birthdate: null,
      gender: '',
      company_id: null,
      company_name: '',
      industry_name: '',
    }
    const toast = useToast()
    const disableBtn = ref(false)
    const companyUsers = ref([])
    // const disableBtn = ref(false)
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const getCompanyUsers = () => {
      store.dispatch('app-user/getCompanyUsers')
        .then(response => {
          companyUsers.value = response.data.data
          companyUsers.value.push({
            value: 0,
            label: 'Other',
          })
        })
    }
    getCompanyUsers()

    const onSubmit = () => {
      disableBtn.value = true
      userData.value.user_id = store.state.currentUser.id
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'User successfully created!',
              icon: 'CheckIcon',
              variant: 'success',
              text: '',
            },
          })
          disableBtn.value = false
        })
        .catch(error => {
          if (error.response !== undefined){
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${error.response.status} Error has occured`,
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            disableBtn.value = false
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      disableBtn,
      companyUsers,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
